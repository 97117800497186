<template>
  <b-list-group-item class="d-flex align-items-center">
    <!-- Avatar (Day of Week) -->
    <b-avatar
      :variant="_working ? 'primary' : 'secondary'"
      :text="dayOfWeek"
      class="mr-3"
    />

    <!-- Day, Status, Time & Toggle -->
    <div class="mr-auto d-flex flex-column">
      <span :class="!_working ? 'text-muted' : ''" class="text-capitalize">
        <b>{{ day }}</b>
      </span>

      <!-- Closed -->
      <span v-if="!_working">Closed</span>

      <!-- ELSE -->
      <!-- Time -->
      <span v-if="_working" class="text-muted">
        {{ `${openTime} - ${closeTime}` }}
      </span>

      <!-- Change -->
      <a @click.prevent="change" href="#" class="stretched-link">
        <small>{{ _working ? "Change" : "" }}</small>
      </a>
    </div>

    <b-form-checkbox v-model="checked" @change="onCheckChange" switch />
  </b-list-group-item>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { updateBusinessDay } from "../../../apis/merchant";
export default {
  props: ["day", "working", "openTime", "closeTime"],
  data() {
    return {
      checked: this.working,
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
    dayOfWeek() {
      return this.day ? this.day.charAt(0) : " ";
    },
    _working() {
      return this.checked;
    },
  },
  methods: {
    ...mapActions({
      setBusinessHours: "merchant/businessHours/setBusinessHours",
    }),
    change() {
      this.$router.push({
        name: "BusinessHourForm",
        params: { day: this.day },
      });
    },
    onCheckChange(value) {
      if (this.day) {
        const form = {
          working: value,
          openTime: this.openTime,
          closeTime: this.closeTime,
        };
        updateBusinessDay(this.day, form, this.token).then((data) => {
          this.setBusinessHours(data);
        });
      }
    },
  },
};
</script>
