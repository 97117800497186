<template>
  <div>
    <b-row>
      <b-col offset-lg="3" lg="6" offset-sm="2" sm="8">
        <b-card>
          <b-list-group>
            <BusinessHour
              v-for="day in days"
              :key="day"
              :day="day"
              :working="businessHours[day].working"
              :openTime="businessHours[day].openTime"
              :closeTime="businessHours[day].closeTime"
            />
          </b-list-group>

          <b-button @click.prevent="back" class="mt-3" block>
            Back
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BusinessHour from "../../../../components/store/businessHours/BusinessHour.vue";
export default {
  components: { BusinessHour },
  created() {
    this.initBusinessHours(this.token);
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
      businessHours: "merchant/businessHours/getBusinessHours",
    }),
    days() {
      return Object.keys(this.businessHours);
    },
  },
  methods: {
    ...mapActions({
      initBusinessHours: "merchant/businessHours/initBusinessHours",
    }),
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
